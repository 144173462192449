<template>
  <div class="LoginPage">
    <div class="loginWrapper">
      <div class="logo">
        <img src="@/assets/images/logo.png" alt="" />
      </div>
      <div class="loginForm">
        <div class="title">登录</div>
        <div class="row">
          <el-input
            v-model="form.name"
            size="large"
            placeholder="请输入邮箱号"
            :suffix-icon="UserFilled"
          />
        </div>
        <div class="row">
          <el-input
            v-model="form.password"
            size="large"
            type="password"
            placeholder="请输入密码"
            :suffix-icon="Basketball"
          />
        </div>
        <div class="row">
          <el-button
            @click="loginAction"
            :disabled="!form.name || !form.password"
            style="width: 100%"
            type="primary"
            size="large"
            >登录</el-button
          >
        </div>
        <div class="row">
          还没有账号？
          <router-link to="/register"
            ><span class="lighight">去注册</span></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { nextTick, ref } from "vue";
import { Basketball, UserFilled } from "@element-plus/icons-vue";
import { login } from "@/service/api.js";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";

const router = useRouter();
const form = ref({
  name: "",
  password: "",
});
const loginAction = () => {
  login({ name: form.value.name, password: form.value.password }).then(
    (res) => {
      if (res?.code == 200) {
        sessionStorage.setItem("userName", form.value.name);
        sessionStorage.setItem("userPwd", res.data);
        ElMessage({
          message: "登录成功",
          type: "success",
        });
        setTimeout(() => {
          router.push("/home/cartoonList");
        }, 200);
      } else {
        ElMessage({
          message: res?.data || "系统错误，请稍后再试！",
          type: "error",
        });
      }
    }
  );
};
</script>
<style lang="scss" scoped>
$color: red;
.LoginPage {
  height: 100%;
  .loginWrapper {
    width: 380px;
    margin: 0 auto;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    .logo {
      text-align: center;
      img {
        width: 150px;
      }
    }
    .loginForm {
      background: #fff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      padding: 31px 20px 25px;
      .title {
        text-align: center;
        font-size: 18px;
        font-weight: 600;
      }
      .row {
        margin-top: 20px;
        text-align: center;
      }
    }
  }
}
</style>
