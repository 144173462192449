<template>
  <div class="cartoonListPage commonComPage">
    <div class="pageChild">
      <div class="searchRow moreOpa">
        <el-input
          type="text"
          v-model="pageData.search"
          placeholder="请输入分类名"
        >
          <template #append>
            <el-button :icon="Search" @click="searchPostCartoon" />
          </template>
        </el-input>
        <el-button style="margin-left: 20px" type="primary" @click="showDialog"
          >添加分类</el-button
        >
      </div>
      <el-table :data="tableData" border v-loading="pageData.loading">
        <el-table-column label="ID" prop="id" />
        <el-table-column label="分类名称" prop="title" />
        <el-table-column label="创建时间" prop="time" />
        <el-table-column fixed="right" label="操作" width="180">
          <template #default="scope">
            <el-button type="primary" @click.prevent="editRow(scope.row)">
              编辑
            </el-button>
            <el-button type="danger" @click.prevent="deleteRow(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagenationRow">
        <el-pagination
          background
          :page-size="pageData.pageSize"
          :current-page="pageData.pageNo"
          layout="prev, pager, next, sizes"
          :total="pageData.total"
          @current-change="currentChange"
          @size-change="sizeChange"
          :hide-on-single-page="true"
        />
      </div>
    </div>
  </div>
  <el-dialog v-model="pageData.dialogFormVisible" :title="pageData.dialogTitle">
    <el-form :model="form" v-loading="pageData.editLoading">
      <el-form-item
        label="分类ID"
        :label-width="pageData.formLabelWidth"
        v-if="form.id"
      >
        <el-input disabled v-model="form.id" autocomplete="off" />
      </el-form-item>
      <el-form-item label="分类名称" :label-width="pageData.formLabelWidth">
        <el-input
          v-model="form.title"
          autocomplete="off"
          placeholder="请输入分类名称"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="pageData.dialogFormVisible = false">取消</el-button>
        <el-button
          type="primary"
          @click="submt"
          :disabled="pageData.editLoading"
          >修改</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { Search } from "@element-plus/icons-vue";
import { getCategoryList, delCategory, updateCategory } from "@/service/api.js";
const tableData = ref([]);
const form = ref({
  id: "",
  title: "",
  time: "",
});
const pageData = ref({
  dialogFormVisible: false,
  formLabelWidth: "80px",
  dialogTitle: "添加分类",
  loading: false,
  editLoading: false,
  total: 10,
  pageSize: 10,
  pageNo: 1,
  search: "",
});

const showDialog = () => {
  form.value.id = "";
  form.value.title = "";
  pageData.value.dialogTitle = "添加分类";
  pageData.value.dialogFormVisible = true;
};
const editRow = (row) => {
  form.value.id = row.id;
  form.value.title = row.title;
  pageData.value.dialogTitle = "修改分类";
  pageData.value.dialogFormVisible = true;
};
const submt = () => {
  pageData.value.editLoading = true;
  updateCategory({ ...form.value })
    .then((res) => {
      if (res?.code == 200) {
        ElMessage({
          message: "操作成功",
          type: "success",
        });
        pageData.value.dialogFormVisible = false;
        gePostCategoryList();
      } else {
        ElMessage({
          message: "操作失败：" + res?.data,
          type: "success",
        });
      }
      pageData.value.editLoading = false;
    })
    .catch(() => {
      pageData.value.editLoading = false;
    });
};
const deleteRow = (row) => {
  ElMessageBox.confirm("是否确定删除：" + row.title, "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
    center: true,
  }).then(() => {
    delCategory(row.id).then((res) => {
      if (res?.code == 200) {
        ElMessage({
          message: "删除成功",
          type: "success",
        });
        gePostCategoryList();
      } else {
        ElMessage({
          message: "操作失败：" + res?.data,
          type: "success",
        });
      }
    });
  });
};

const currentChange = (no) => {
  pageData.value.pageNo = no;
  gePostCategoryList();
};

const sizeChange = (no) => {
  pageData.value.pageSize = no;
  gePostCategoryList();
};

const searchPostCartoon = () => {
  gePostCategoryList();
};

const gePostCategoryList = () => {
  pageData.value.loading = true;
  getCategoryList({
    limit: pageData.value.pageSize,
    page: pageData.value.pageNo,
    search: pageData.value.search,
  })
    .then((res) => {
      pageData.value.loading = false;
      if (res?.code == 200) {
        tableData.value = res.data?.data || [];
        pageData.value.total = Number(res.data?.cnt) || 0;
      }
    })
    .catch(() => {
      pageData.value.loading = false;
    });
};
onMounted(() => {
  gePostCategoryList();
});
</script>
<style lang="scss" scoped>
.cartoonListPage {
}
</style>
