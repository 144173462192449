<template>
  <div class="cartoonListPage commonComPage">
    <div class="pageChild">
      <div class="searchRow">
        <el-input
          type="text"
          v-model="pageData.search"
          placeholder="请输入漫画名称、作者"
        >
          <template #append>
            <el-button :icon="Search" @click="searchPostCartoon" />
          </template>
        </el-input>
      </div>
      <el-table :data="tableData" border v-loading="pageData.loading">
        <el-table-column label="漫画名称" prop="name" width="150px" />
        <el-table-column label="作者" prop="author" width="150px" />
        <el-table-column label="封面" prop="cover">
          <template #default="scope">
            <img class="cover" :src="getImageUrl(scope.row.cover)" alt="" />
          </template>
        </el-table-column>
        <el-table-column label="简介" prop="description" min-width="200px">
          <template #default="scope">
            <div class="moreRow">{{ scope.row.description }}</div>
          </template>
        </el-table-column>
        <el-table-column label="分类" prop="category" min-width="150px">
          <template #default="scope">
            <div>
              <template v-if="scope.row.category">
                <el-tag
                  v-for="(item, index) in scope.row.category.split(',')"
                  :key="index"
                  >{{ item }}</el-tag
                >
              </template>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="标签" prop="tag" min-width="150px">
          <template #default="scope">
            <div>
              <template v-if="scope.row.tag">
                <el-tag
                  v-for="(item, index) in scope.row.tag.split(',')"
                  :key="index"
                  >{{ item }}</el-tag
                >
              </template>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column label="点赞量" prop="thumbcnt" /> -->
        <el-table-column label="收藏量" prop="collect" />
        <el-table-column label="阅读量" prop="readcnt" />
        <el-table-column label="评分" prop="rate" />
        <el-table-column label="创建时间" prop="time" width="180px" />
        <el-table-column fixed="right" label="操作" width="180">
          <template #default="scope">
            <el-button type="primary" @click.prevent="editRow(scope.row)">
              编辑
            </el-button>
            <el-button type="danger" @click.prevent="deleteRow(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagenationRow">
        <el-pagination
          background
          :page-size="pageData.pageSize"
          :current-page="pageData.pageNo"
          layout="prev, pager, next, sizes"
          :total="pageData.total"
          @current-change="currentChange"
          @size-change="sizeChange"
          :hide-on-single-page="true"
        />
      </div>
    </div>
  </div>
  <el-dialog v-model="pageData.dialogFormVisible" title="编辑漫画">
    <el-form :model="form" v-loading="pageData.editLoading">
      <el-form-item label="漫画名称" :label-width="pageData.formLabelWidth">
        <el-input v-model="form.name" autocomplete="off" />
      </el-form-item>
      <el-form-item label="作者" :label-width="pageData.formLabelWidth">
        <el-input v-model="form.author" autocomplete="off" />
      </el-form-item>
      <el-form-item label="封面" :label-width="pageData.formLabelWidth">
        <el-input v-model="form.cover" autocomplete="off" />
      </el-form-item>
      <el-form-item label="简介" :label-width="pageData.formLabelWidth">
        <el-input
          v-model="form.description"
          :autosize="{ minRows: 2, maxRows: 4 }"
          autocomplete="off"
          type="textarea"
          placeholder="简介"
        />
      </el-form-item>
      <!-- <el-form-item label="点赞量" :label-width="pageData.formLabelWidth">
        <el-input v-model="form.thumbcnt" autocomplete="off" />
      </el-form-item> -->
      <el-form-item label="收藏量" :label-width="pageData.formLabelWidth" >
        <el-input v-model="form.collect" autocomplete="off" disabled />
      </el-form-item>
      <el-form-item label="阅读量" :label-width="pageData.formLabelWidth">
        <el-input v-model="form.readcnt" autocomplete="off" />
      </el-form-item>
      <el-form-item label="评分" :label-width="pageData.formLabelWidth">
        <el-input v-model="form.rate" autocomplete="off" />
      </el-form-item>
      <el-form-item label="分类" :label-width="pageData.formLabelWidth">
        <el-select multiple v-model="form.category" placeholder="">
          <el-option
            v-for="(item, index) in categoryList"
            :key="index"
            :label="item.title"
            :value="item.title"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="标签" :label-width="pageData.formLabelWidth">
        <el-select multiple v-model="form.tag" placeholder="">
          <el-option
            v-for="(item, index) in tagList"
            :key="index"
            :label="item.title"
            :value="item.title"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="pageData.dialogFormVisible = false">取消</el-button>
        <el-button
          type="primary"
          @click="submt"
          :disabled="pageData.editLoading"
          >修改</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { Search } from "@element-plus/icons-vue";
import {
  getCartoonList,
  delCartoon,
  updateCartoon,
  getCategoryList,
  getTagList,
} from "@/service/api.js";
import { getEnvironment, EnvironmentUrl } from "@/utils";

const tableData = ref([]);

const pageData = ref({
  dialogFormVisible: false,
  formLabelWidth: "100px",
  loading: false,
  editLoading: false,
  total: 10,
  pageSize: 10,
  pageNo: 1,
  search: "",
});
const categoryList = ref([]);
const tagList = ref([]);
const curSelect = ref({});
const form = ref({
  name: "",
  author: "",
  cover: "",
  description: "",
  //thumbcnt: "",
  //collect: "",
  readcnt: "",
  rate: "",
  category: "",
  tag: "",
});
const editRow = (row) => {
  pageData.value.dialogFormVisible = true;
  curSelect.value = { ...row };
  form.value = { ...row };
  form.value.category = row.category ? row.category.split(",") : [];
  form.value.tag = row.tag ? row.tag.split(",") : [];
};
const submt = () => {
  pageData.value.editLoading = true;
  updateCartoon({ ...form.value })
    .then((res) => {
      if (res?.code == 200) {
        ElMessage({
          message: "修改成功",
          type: "success",
        });
        pageData.value.dialogFormVisible = false;
        gePostCartoonList();
      } else {
        ElMessage({
          message: "操作失败：" + res?.data,
          type: "success",
        });
      }
      pageData.value.editLoading = false;
    })
    .catch(() => {
      pageData.value.editLoading = false;
    });
};
const deleteRow = (row) => {
  ElMessageBox.confirm("是否确定删除：" + row.name, "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
    center: true,
  }).then(() => {
    delCartoon(row.id).then((res) => {
      if (res?.code == 200) {
        ElMessage({
          message: "删除成功",
          type: "success",
        });
        gePostCartoonList();
      } else {
        ElMessage({
          message: "操作失败：" + res?.data,
          type: "success",
        });
      }
    });
  });
};

const getImageUrl = (src) => {
  const env = getEnvironment();
  let url = EnvironmentUrl.imgProd;
  if (env !== "prod") {
    url = EnvironmentUrl.imgTest;
  }
  return url + src;
};

const currentChange = (no) => {
  pageData.value.pageNo = no;
  gePostCartoonList();
};

const sizeChange = (no) => {
  pageData.value.pageSize = no;
  gePostCartoonList();
};

const searchPostCartoon = () => {
  gePostCartoonList();
};

const gePostCartoonList = () => {
  pageData.value.loading = true;
  getCartoonList({
    limit: pageData.value.pageSize,
    page: pageData.value.pageNo,
    search: pageData.value.search,
  })
    .then((res) => {
      pageData.value.loading = false;
      if (res?.code == 200) {
        tableData.value = res.data?.data || [];
        pageData.value.total = Number(res.data?.cnt) || 0;
      }
    })
    .catch(() => {
      pageData.value.loading = false;
    });
};
// 获取分类列表
const getPostCategoryList = () => {
  getCategoryList({
    limit: 100,
    page: 1,
  }).then((res) => {
    if (res?.code == 200) {
      categoryList.value = res.data?.data || [];
    }
  });
};

// 获取标签列表
const getPostTagList = () => {
  getTagList({
    limit: 100,
    page: 1,
  }).then((res) => {
    if (res?.code == 200) {
      tagList.value = res.data?.data || [];
    }
  });
};

onMounted(() => {
  gePostCartoonList();
  getPostCategoryList();
  getPostTagList();
});
</script>
<style lang="scss" scoped>
.cartoonListPage {
  .el-table {
    .cover {
      width: 40px;
      max-height: 80px;
    }
  }
}
</style>
