import { ElMessage } from "element-plus";

const mimeJSON = "application/json";

function converyUrl(url, query) {
  if (query) {
    let search;
    switch (typeof query) {
      case "object":
        search = urlStringify(query);
        break;
      case "string":
        search = query;
        break;
    }
    if (search) {
      url = [url, search].join(url.indexOf("?") === -1 ? "?" : "&");
    }
  }
  return url;
}

function urlStringify(object) {
  const keys = Object.keys(object);
  return keys
    .map((key) => {
      const value = object[key];
      if (typeof value === "undefined") {
        return "";
      }
      if (value === null) {
        return key;
      }
      return key + "=" + encodeURIComponent(value);
    })
    .filter((x) => x.length > 0)
    .join("&");
}
export function get(url, query, headers) {
  return fetch("GET", converyUrl(url, query), null, headers);
}

export function post(url, payload, headers) {
  return fetch("POST", url, payload, headers);
}

export function fetch(method, url, body, headers) {
  method = method.toUpperCase();
  headers = headers || {};
  if ((method === "POST" || method === "PUT") && !(body instanceof FormData)) {
    headers["Content-Type"] = mimeJSON;
    if (typeof body === "object") {
      body = JSON.stringify(body);
    }
  }
  const defaultHeader = {
    "c-token": sessionStorage.getItem("userPwd"),
    "c-username": sessionStorage.getItem("userName"),
  };
  headers = { ...headers, ...defaultHeader };
  // debugger;
  if (
    url.indexOf("/login") === -1 &&
    url.indexOf("/register") === -1 &&
    (!headers["c-token"] || !headers["c-username"])
  ) {
    new Promise((resolve, reject) => {
      reject({ code: 401 });
    });
    window.location.href = "/login";
    return;
  }
  return new Promise((resolve, reject) => {
    window
      .fetch(url, {
        method,
        body,
        headers,
        cache: "no-cache",
        mode: "cors",
      })
      .then((Response) => {
        let resContentType = Response.headers.get("content-type") || mimeJSON;
        if (resContentType.indexOf(mimeJSON) === 0) {
          Response.json().then((json) => {
            if (json.code == 401) {
              reject({ code: 401 });
              ElMessage({
                message: "当前会话失效，请重新登录！",
                type: "error",
              });
              sessionStorage.removeItem("userName");
              sessionStorage.removeItem("userPwd");
              window.location.href = "/login";
            } else {
              resolve(json);
            }
          });
        } else if (resContentType.indexOf("text/") === 0) {
          Response.text().then((text) => {
            resolve(text);
          });
        } else {
          Response.blob().then((blob) => {
            resolve(blob);
          });
        }
      })
      .catch((error) => {
        console.log(error);
        ElMessage({ message: "系统错误，请稍后再试：" + error, type: "error" });
        reject(new Error("fetch catch error"));
      });
  });
}
