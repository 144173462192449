import { post, get } from "./fetch";
import { getEnvironment, EnvironmentUrl } from "@/utils";

const env = getEnvironment();
let url = "";

switch (env) {
  case "dev":
    url = EnvironmentUrl.dev;
    break;
  case "test":
    url = EnvironmentUrl.test;
    break;
  case "prod":
    url = EnvironmentUrl.prod;
    break;
}

export function login({ name, password }) {
  return post(`${url}/admin/login`, { name, password });
}

export function register({ name, password }) {
  return post(`${url}/admin/register`, { name, password });
}
// 漫画管理
export function getCartoonList(params) {
  return post(`${url}/admin/cartoon/List`, params);
}

export function delCartoon(id) {
  return post(`${url}/admin/cartoon/delete`, { id });
}

export function updateCartoon(params) {
  return post(`${url}/admin/cartoon/update`, params);
}

export function searchCartoon(keyword) {
  return post(`${url}/admin/cartoon/search`, { keyword });
}
// 支付管理
export function getPayList(params) {
  return post(`${url}/admin/pay/List`, params);
}

export function updatePay(params) {
  return post(`${url}/admin/pay/update`, params);
}

export function searchPay(keyword) {
  return post(`${url}/admin/pay/search`, { keyword });
}
// 用户管理
export function getUserList(params) {
  return post(`${url}/admin/user/List`, params);
}

export function updateUser(params) {
  return post(`${url}/admin/user/update`, params);
}

export function searchUser(keyword) {
  return post(`${url}/admin/user/search`, { keyword });
}
// 分类管理
export function getCategoryList(params) {
  return post(`${url}/admin/category/List`, params);
}

export function delCategory(id) {
  return post(`${url}/admin/category/delete`, { id });
}

export function updateCategory(params) {
  return post(`${url}/admin/category/update`, params);
}

export function searchCategory(keyword) {
  return post(`${url}/admin/category/search`, { keyword });
}
// 标签管理
export function getTagList(params) {
  return post(`${url}/admin/tag/List`, params);
}

export function delTag(id) {
  return post(`${url}/admin/tag/delete`, { id });
}

export function updateTag(params) {
  return post(`${url}/admin/tag/update`, params);
}

export function searchTag(keyword) {
  return post(`${url}/admin/tag/search`, { keyword });
}
