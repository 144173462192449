<template>
  <div class="appPage">
    <router-view></router-view>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "App",
  components: {},
  setup() {
    const name = ref("tangjians");

    return {
      name,
    };
  },
});
</script>
<style lang="scss">
#app {
  height: 100%;
}
.appPage {
  height: 100%;
}
</style>
