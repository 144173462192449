import { createStore } from "vuex";

export default createStore({
  state: {
    taskList: [
      { name: "吃饭", isCompeted: false },
      { name: "睡觉", isCompeted: true },
      { name: "写代码", isCompeted: false },
    ],
  },
  getters: {
    getTakeList(state) {
      return state.taskList;
    },
  },
  mutations: {
    addTask(state, addItem) {
      let arr = state.taskList.filter((item) => item.name == addItem.name);
      if (arr.length) {
        return;
      }
      state.taskList.push(addItem);
    },
    editTask(state, editItem = {}) {
      if (state.taskList.length) {
        for (let i = 0; i < state.taskList.length; i++) {
          let item = state.taskList[i];
          if (item && item.name == editItem.name) {
            item = editItem;
            break;
          }
        }
      }
    },
    delCompetedTask(state, data) {
      state.taskList = data;
    },
    delTask(state, index) {
      state.taskList.splice(index, 1);
    },
  },
  actions: {},
  modules: {},
});
