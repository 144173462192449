<template>
  <div class="cartoonListPage commonComPage">
    <div class="pageChild">
      <div class="searchRow">
        <el-input
          type="text"
          v-model="pageData.search"
          placeholder="请输入用户邮箱"
        >
          <template #append>
            <el-button :icon="Search" @click="searchPostCartoon" />
          </template>
        </el-input>
      </div>
      <el-table :data="tableData" border v-loading="pageData.loading">
        <el-table-column label="ID" prop="uid" />
        <el-table-column label="用户邮箱" prop="email" />
        <el-table-column label="阅读到期时间" prop="expiretime" />
        <el-table-column label="代理" prop="agent" />
        <el-table-column label="邀请数" prop="invitecnt" />
        <el-table-column label="邀请码" prop="promote" />
        <el-table-column label="注册时间" prop="regtime" />
      </el-table>
      <div class="pagenationRow">
        <el-pagination
          background
          :page-size="pageData.pageSize"
          :current-page="pageData.pageNo"
          layout="prev, pager, next, sizes"
          :total="pageData.total"
          @current-change="currentChange"
          @size-change="sizeChange"
          :hide-on-single-page="true"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { Search } from "@element-plus/icons-vue";
import { getUserList, updateUser } from "@/service/api.js";
const tableData = ref([]);
const pageData = ref({
  loading: false,
  total: 10,
  pageSize: 10,
  pageNo: 1,
  search: "",
});

const currentChange = (no) => {
  pageData.value.pageNo = no;
  gePostUserList();
};

const sizeChange = (no) => {
  pageData.value.pageSize = no;
  gePostUserList();
};

const searchPostCartoon = () => {
  gePostUserList();
};

const gePostUserList = () => {
  pageData.value.loading = true;
  getUserList({
    limit: pageData.value.pageSize,
    page: pageData.value.pageNo,
    search: pageData.value.search,
  })
    .then((res) => {
      pageData.value.loading = false;
      if (res?.code == 200) {
        tableData.value = res.data?.data || [];
        pageData.value.total = Number(res.data?.cnt) || 0;
      }
    })
    .catch(() => {
      pageData.value.loading = false;
    });
};
onMounted(() => {
  gePostUserList();
});
</script>
<style lang="scss" scoped>
.cartoonListPage {
}
</style>
