import { createRouter, createWebHistory } from "vue-router";
import Login from "@/views/Login";
import Register from "@/views/Register";
import CartoonList from "@/views/CartoonList";
import PayList from "@/views/PayList";
import UserList from "@/views/UserList";
import CategeryList from "@/views/CategeryList";
import TagList from "@/views/TagList";

const routes = [
  {
    path: "/",
    redirect: "/home/cartoonList",
  },
  {
    path: "/home",
    redirect: "/home/cartoonList",
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/home",
    name: "Home",
    component: function () {
      return import(/* webpackChunkName: "Home" */ "../views/Home");
    },
    children: [
      {
        path: "/home/cartoonList",
        name: "CartoonList",
        component: CartoonList,
      },
      {
        path: "/home/payList",
        name: "PayList",
        component: PayList,
      },
      {
        path: "/home/userList",
        name: "UserList",
        component: UserList,
      },
      {
        path: "/home/categeryList",
        name: "CategeryList",
        component: CategeryList,
      },
      {
        path: "/home/tagList",
        name: "TagList",
        component: TagList,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
